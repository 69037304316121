<template>
    <div class="main-cont__right hide-tablet">
        <a href="#" class="box-social-group box-social-group_top">
            <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Funiquerewards%2F&tabs=timeline&width=260&height=350&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=913831039984187"
                width="260"
                height="350"
                style="border: none; overflow: hidden"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            />
        </a>
        <a href="#" class="box-social-group">
            <a
                class="twitter-timeline"
                data-width="260"
                data-height="350"
                href="https://twitter.com/uniqueRewards?ref_src=twsrc%5Etfw"
            >
                Tweets by uniqueRewards
            </a>
        </a>
        <right-banner />
    </div>
</template>
<script>
export default {
    expose: [],
    mounted() {
        twttr.widgets.load();
    },
};
</script>
