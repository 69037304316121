import Vue from 'vue';
import VueI18n from 'vue-i18n';
import us from './locales/us.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: process.env.VUE_APP_DEFAULT_LOCALE,
    messages: { us },
    fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
});
