<template>
    <li class="list-social__item" @click.prevent="loginWithFacebook">
        <a href="#" class="list-social__link">
            <img src="@assets/img/img_social_2.jpg" alt />
        </a>
    </li>
</template>

<script>
import axios from 'axios';
import { initFbsdk } from 'configs/facebook_oAuth.js';

export default {
    emits: ['success'],
    expose: [],
    mounted() {
        initFbsdk();
    },
    methods: {
        loginWithFacebook() {
            FB.login(
                async (response) => {
                    if (!response.authResponse) return;

                    const { data: authData } = await axios.post(
                        'service/fbLogin',
                        {
                            fp_val: this.$store.getters.visitorId,
                            fbToken: response.authResponse.accessToken,
                            locale: this.$Trans.currentLocale,
                        },
                    );
                    this.$store.dispatch('authentication', {
                        status: true,
                        token: authData.result.token,
                    });
                    this.$store.dispatch('updateUserData', {
                        token: authData.result.token,
                    });

                    this.$emit('success');
                },

                { scope: 'public_profile,email' },
            );
        },
    },
};
</script>
