<template>
    <div />
</template>

<script setup>
import { getBrkCode } from '~/api/services';
import { ref, watch } from 'vue';

const brokerHeadScripts = ref();
const brokerBodyScripts = ref();

getBrkCode(0).then(
    (response) => {
        brokerHeadScripts.value = response.data.result.code;
    },
    (error) => {
        brokerHeadScripts.value = '';
    },
);

getBrkCode(1).then(
    (response) => {
        brokerBodyScripts.value = response.data.result.code;
    },
    (error) => {
        brokerHeadScripts.value = '';
    },
);

const createElement = (html, target) => {
    const templateElement = document.createElement('template');
    templateElement.innerHTML = html;
    document[target].appendChild(templateElement.content);
};

watch(brokerHeadScripts, () => {
    if (brokerHeadScripts.value?.length > 0) {
        for (let i = 0; i < brokerHeadScripts.value.length; i++) {
            createElement(brokerHeadScripts.value[i].code, 'head');
        }
    }
    if (brokerBodyScripts.value?.length > 0) {
        for (let i = 0; i < brokerBodyScripts.value.length; i++) {
            createElement(brokerBodyScripts.value[i].code, 'body');
        }
    }
});
</script>
