export const initGoogleOauth = (callback, nonce) => google.accounts.id.initialize({
    client_id:
            '316283462977-nc0u7qrs7e0coos3isbrn9f5n5rp8r6t.apps.googleusercontent.com',
    callback,
    nonce,
    context: 'signin',
    mouseDown: 0,
    ux_mode: 'popup',
    auto_select: true,
    itp_support: true,
});
