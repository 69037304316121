<template>
    <BaseModal title="Please enter User ID of the user who attracted you" name="enter-referral-uid" closable height="291px" adaptive>
        <template #content>
            <form @submit.prevent>
                <label hidden for="referral-uid" class="form-label">Referral uid</label>
                <input
                    id="referral-uid"
                    v-model="form.referralUid.value"
                    required
                    placeholder="Referral uid"
                    type="text"
                    class="form-control"
                />
                <button class="button button_orange button_large mr-2" @click.prevent="enter">
                    Confirm
                </button>
                <button class="button button_orange button_large" @click.prevent="skip">
                    Skip
                </button>
            </form>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '~/views/components/BaseModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: { BaseModal },
    expose: [],
    emits: ['submit', 'skip'],
    data() {
        return {
            form: {
                referralUid: {
                    value: '',
                    required: true,
                    errorMsg: '',
                },
            },
        };
    },
    methods: {
        enter() {
            localStorage.setItem('urRefid', this.form.referralUid.value);
            this.$modal.hide('enter-referral-uid');
            this.$emit('submit');
        },
        skip() {
            this.$modal.hide('enter-referral-uid');
            this.$emit('skip');
        },
    },
});
</script>

<style scoped>
    .form-control {
        margin-bottom: 1rem;
    }
</style>
