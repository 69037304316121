import Vue from 'vue';
import VueRouter from 'vue-router';
import { Trans } from '~/i18n';
import store from '@/store';
import { routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
    },
});

/*

  ПРОВЕРКА АВТОРИЗАЦИИ ПРИ ПЕРВОНАЧАЛЬНОЙ ЗАГРУЗКЕ

*/
const { token } = store.getters;
if (token) {
    store.dispatch('authentication', { status: true, token });
    store.dispatch('getUserData');
} else {
    store.dispatch('authentication', { status: false });
}

router.beforeEach((to, from, next) => {
    store.state.base.preloader = true;
    const { authorize } = to.meta;

    /*
        ЗАПРОС НА СЕРВЕР

        проверка токена на просроченность
    */
    axios
        .get('/service/checkAuth')
        .then((response) => {
            try {
                const _response = JSON.parse(response.request.responseText);
                if (_response.result && _response.result.authorized === true) {
                    store.dispatch('updateUserData', _response.result);
                    next();
                } else if (authorize) {
                    store.dispatch('authentication', { status: false });
                    store.dispatch('updateUserData', { token: '' });
                    next(Trans.i18nRoute({ name: 'home' }));
                } else if (authorize) {
                    store.dispatch('authentication', { status: false });
                    store.dispatch('updateUserData', { token: '' });
                    next(Trans.i18nRoute({ name: 'register' }));
                } else {
                    next();
                }
            } catch (e) {
                store.dispatch('updateUserData', { token: '' });
                store.dispatch('authentication', { status: false });
                next(Trans.i18nRoute({ name: 'register' }));
            }
        })
        .catch(() => {
            store.dispatch('authentication', { status: false });

            if (authorize) {
                store.state.base.preloader = false;
                store.dispatch('authentication', { status: false });
                next(Trans.i18nRoute({ name: 'register' }));
            } else {
                next();
            }
        });
});

router.afterEach((to, from) => {
    store.state.base.preloader = false;
    if (window.scrollTo) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
});

export default router;
