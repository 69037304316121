<template>
    <div
        class="p-5 d-flex justify-content-center align-item-center flex-column text-center position-relative"
    >
        <h3>
            Would you like to register in the
            {{ localeByServer ? localeByServer.toUpperCase() : '...' }} version
            of the site that matches your IP?
        </h3>
        <div
            class="pt-5 d-flex justify-content-center align-item-center flex-row"
        >
            <button
                :key="$Trans.currentLocale"
                class="button button_large-text mx-3 w-25"
                type="button"
                @click="emit('close', { locale: $Trans.currentLocale })"
            >
                {{ getEmojiByCountryCode($Trans.currentLocale) }}
            </button>

            <button
                key="content"
                class="button button_large-text mx-3 w-25"
                :class="{ disabled: !localeByServer }"
                type="button"
                :disabled="!localeByServer"
                @click="emit('close', { locale: localeByServer })"
            >
                {{ getEmojiByCountryCode(localeByServer) ?? '...' }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getEmojiByCountryCode } from '~/utils/helpers';
import { useTranslation } from '~/composables/useTranslation';

const emit = defineEmits(['close']);

const translation = useTranslation();
const localeByServer = ref('');

onMounted(async () => {
    localeByServer.value = await translation.getLocaleByServer();
});
</script>
