<template>
    <aside>
        <ul class="menu">
            <router-link
                :to="$Trans.i18nRoute({ name: 'hot-offers' })"
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_3.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Best</span>
                    </span>
                </a>
            </router-link>
            <router-link
                :to="$Trans.i18nRoute({ name: 'offers' })"
                exact
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_1.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Earn Money</span>
                    </span>
                </a>
            </router-link>
            <router-link
                :to="$Trans.i18nRoute({ name: 'surveys' })"
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_2.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Surveys for Money</span>
                    </span>
                </a>
            </router-link>
            <router-link
                :to="$Trans.i18nRoute({ name: 'paid-2-games' })"
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_6.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Play to Earn</span>
                    </span>
                </a>
            </router-link>
            <router-link
                :to="$Trans.i18nRoute({ name: 'video' })"
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_8.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Watch & Earn</span>
                    </span>
                </a>
            </router-link>
            <router-link
                :to="$Trans.i18nRoute({ name: 'click' })"
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_10.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Click & Earn</span>
                    </span>
                </a>
            </router-link>
            <router-link
                :to="$Trans.i18nRoute({ name: 'offer-walls' })"
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_4.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Offer Walls</span>
                    </span>
                </a>
            </router-link>
            <router-link
                :to="$Trans.i18nRoute({ name: 'referals' })"
                tag="li"
                class="menu__item"
            >
                <a href="#" class="menu__link">
                    <span class="menu__icon">
                        <img src="@assets/img/icon_menu_12.png" alt="" />
                    </span>
                    <span class="menu__info">
                        <span class="menu__info-cont">Refer & Earn</span>
                    </span>
                </a>
            </router-link>
        </ul>
        <left-banner />
    </aside>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['auth']),
    },
};
</script>
