import { request } from '~/api';

export const configService = () => {
    const getConfig = async () => request({
        method: 'GET',
        url: '/service/config',
    });

    return { getConfig };
};
