<template>
    <header>
        <div class="wrapper header-wrapper">
            <div class="header-cols">
                <div class="logo">
                    <router-link style="display: flex" :to="$Trans.i18nRoute({ name: 'home' })">
                        <span class="logo__img">
                            <img src="@assets/img/logo.png" alt />
                        </span>
                        <span class="logo__text">
                            <span class="logo__title">UniqueRewards</span>
                            <span class="logo__subtitle">
                                EARN EASY PAY! JUST A CLICK AWAY!
                            </span>
                        </span>
                    </router-link>
                </div>
                <div class="header-right">
                    <a v-if="auth && !urBid" class="button-redirect-old-site" href="https://old.uniquerewards.com">
                        <img src="@assets/img/button-redirect-old-site.png" alt="button-redirect-old-site" height="84" />
                    </a>
                    <div v-if="auth" class="btns-user">
                        <router-link
                            :to="$Trans.i18nRoute({ name: 'account' })"
                            class="btns-user__item btn-user"
                        >
                            <span class="btns-user__icon" />
                        </router-link>
                        <a
                            href="#"
                            class="btns-user__item btn-exit"
                            @click.prevent="logout()"
                        >
                            <span class="btns-user__icon" />
                            <span class="btns-user__name">Exit</span>
                        </a>
                    </div>
                    <div class="btns-guest">
                        <a
                            v-if="!auth"
                            href="#"
                            class="button button_blue button_login"
                            @click.prevent="$modal.show('login')"
                        >
                            <span class="icon icon_button-login" />
                            <span class="button-text">Log In</span>
                        </a>

                        <router-link
                            v-if="!auth"
                            :to="$Trans.i18nRoute({ name: 'register' })"
                            class="button button_register"
                        >
                            <span class="icon icon_button-reg" />
                            <span class="button-text">Registration</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <top-banner />
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            urBid: null,
        };
    },
    computed: {
        ...mapGetters(['auth', 'user']),
    },
    mounted() {
        this.urBid = localStorage.getItem('urBid');
    },
    methods: {
        logout() {
            /*

        удаление токена из state и localS

        удаление токена из заголовков axios

        выставление state authenticated = true

        перенаправление на главную страницу

      */
            this.$store.dispatch('authentication', { status: false });
            this.$store.dispatch('updateUserData', { token: '' });

            /*

        ???

        Avoided redundant navigation to current location:

          .catch(()=>{})

     */
            this.$router.push(this.$Trans.i18nRoute({ name: 'home' }));
        },
    },
};
</script>

<style lang="scss" scoped>
.button-redirect-old-site {
    margin-right: 1rem;
    margin-bottom: 4px;
    img {
        height: 48px;
    }
}

.header-right .button {
    margin-left: 3px;
}
.header-right {
    display: flex;
    align-items: center;
}

.header-right {
    text-align: right;
    .button {
        margin-left: 5px;
    }
}

.header-cols {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header-wrapper {
   height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    float: left;
    width: 257px;
    a {
        display: block;
    }
    &__img {
        display: inline-block;
        vertical-align: top;
        margin: 0 -5px 0 0;
        padding: 0 0 0 0;
        width: 41px;
        img {
            display: block;
            width: 100%;
        }
    }
    &__text {
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 0;
        padding: 0 0 0 10px;
        width: 207px;
    }
    &__title {
        display: block;
        padding: 0 0 0 0;
        font-size: 28px;
        line-height: 32px;
        color: #36454e;
        font-weight: 500;
        margin: -5px 0 0 -2px;
    }
    &__subtitle {
        display: block;
        padding: 2px 0 0 0;
        font-size: 11px;
        line-height: 13px;
        color: #7c8a96;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1px;
        white-space: nowrap;
    }
}

@media screen and (max-width: 767px) {
    .button-redirect-old-site {
        margin-right: 0;
        img {
            height: 46px;
        }
    }
    .logo {
        width: 217px;
    }
    .logo__text {
        width: 100px;
    }
}

@media screen and (max-width: 479px) {
    .logo__title {
        text-align: center;
        font-size: small;
        margin: 0;
    }
    .logo__subtitle {
        display: none;
    }
    .logo__text {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 459px) {
    .button_register {
        display: none;
    }
}

@media screen and (max-width: 359px) {
    .logo__title {
        font-size: x-small;
    }
}
</style>
