import LocaleChooseDialog from '~/views/components/LocaleChooseDialog.vue';
import { getGeoListCountries, getUserLocaleByIp } from '~/api/services';
import { nextTick } from 'vue';
import store from '~/store';
import { i18n } from './vueI18n';
import Vue from '../main.js';

export const Trans = {
    get defaultLocale() {
        return process.env.VUE_APP_DEFAULT_LOCALE;
    },

    async getSupportedLocales() {
        return (await getGeoListCountries()).data.result.map((country) => country.CountryCode.toLowerCase());
    },

    get currentLocale() {
        return i18n.locale;
    },

    set currentLocale(newLocale) {
        i18n.locale = newLocale;
    },

    async switchLanguage(newLocale) {
        await Trans.loadLocaleMessages(newLocale);
        Trans.currentLocale = newLocale;
        document.querySelector('html').setAttribute('lang', newLocale);
        localStorage.setItem('user-locale', newLocale);

        return localStorage.getItem('user-locale');
    },

    async loadLocaleMessages(locale) {
        if (!i18n.availableLocales.includes(locale)) {
            const messages = await import(`~/i18n/locales/${locale}.json`);
            i18n.setLocaleMessage(locale, messages.default);
        }

        return nextTick();
    },

    async isLocaleSupported(locale) {
        return (await Trans.getSupportedLocales()).includes(locale);
    },

    getUserLocale() {
        const locale = window.navigator.language
            || window.navigator.userLanguage
            || Trans.defaultLocale;

        return {
            locale,
            localeNoRegion: locale.split('-')[0],
        };
    },

    async getPersistedLocale() {
        const persistedLocale = localStorage.getItem('user-locale');

        if (await Trans.isLocaleSupported(persistedLocale)) {
            return persistedLocale;
        }

        return null;
    },

    async guessDefaultLocale() {
        const userPersistedLocale = await Trans.getPersistedLocale();
        if (userPersistedLocale) {
            return userPersistedLocale;
        }

        const userPreferredLocale = Trans.getUserLocale();

        if (await Trans.isLocaleSupported(userPreferredLocale.locale)) {
            return userPreferredLocale.locale;
        }

        if (await Trans.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
            return userPreferredLocale.localeNoRegion;
        }

        return Trans.defaultLocale;
    },

    async routeMiddleware(to, _from, next) {
        const siteLocale = to.params.locale
            ? to.params.locale
            : process.env.VUE_APP_DEFAULT_LOCALE;

        if (!(await Trans.isLocaleSupported(siteLocale))) {
            return next(await Trans.guessDefaultLocale());
        }

        const { auth } = store.getters;
        if (auth) return next();

        await Trans.switchLanguage(siteLocale);

        return next();
    },

    i18nRoute(to) {
        // fix: "to" is path or object
        return {
            ...to,
            params: {
                ...to.params,
                locale:
                    Trans.currentLocale === process.env.VUE_APP_DEFAULT_LOCALE
                        ? // note: if "undefined" - locale not returned
                        undefined
                        : Trans.currentLocale,
            },
        };
    },

    /**
     * Checks if the user's locale matches the current locale and shows a confirmation dialog if it doesn't.
     * @returns {Promise<string>}
     */
    async getLocaleByServer() {
        return (
            await getUserLocaleByIp()
        ).data.result.county_code.toLowerCase();
    },

    /**
     * Checks if the user's locale matches the current locale and shows a confirmation dialog if it doesn't.
     * @returns {Promise<string>}
     */
    async localeConfirmUser() {
        const localeByServer = await Trans.getLocaleByServer();
        const isLocaleSupported = await Trans.isLocaleSupported(localeByServer);
        const isLocaleNotSupported = !isLocaleSupported;

        if (isLocaleNotSupported || localeByServer === Trans.currentLocale) {
            return;
        }

        return new Promise((resolve) => {
            Vue.$modal.show(
                LocaleChooseDialog,
                null,
                { adaptive: true, height: '250px', clickToClose: false },
                {
                    'before-close': (modal) => resolve(Trans.switchLanguage(modal.params.locale)),
                },
            );
        });
    },
};
