export const videos = [
    {
        alt: 'Video 1',
        videoAsset: require('@assets/img/facebook-videos/video1.mp4'),
        screenAsset: require('@assets/img/facebook-videos/video1.jpg'),
    },
    {
        alt: 'Video 2',
        videoAsset: require('@assets/img/facebook-videos/video2.mp4'),
        screenAsset: require('@assets/img/facebook-videos/video2.jpg'),
    },
    {
        alt: 'Video 3',
        videoAsset: require('@assets/img/facebook-videos/video3.mp4'),
        screenAsset: require('@assets/img/facebook-videos/video3.jpg'),
    },
    {
        alt: 'Video 4',
        videoAsset: require('@assets/img/facebook-videos/video4.mp4'),
        screenAsset: require('@assets/img/facebook-videos/video4.jpg'),
    },
    {
        alt: 'Video 5',
        videoAsset: require('@assets/img/facebook-videos/video5.mp4'),
        screenAsset: require('@assets/img/facebook-videos/video5.jpg'),
    },
    {
        alt: 'Video 6',
        videoAsset: require('@assets/img/facebook-videos/video6.mp4'),
        screenAsset: require('@assets/img/facebook-videos/video6.jpg'),
    },
];
