import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';

import axios from './api/index.js';
import $ from 'jquery';

import Vue from 'vue';

// PLUGINS
import VModal from 'vue-js-modal';
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { createHead } from '@unhead/vue';
import { UnheadPlugin } from '@unhead/vue/vue2';

// GLOBAL COMPONENTS
import AsideLeft from 'components/layout/AsideLeft';
import SocialRight from 'components/layout/SocialRight';
import UserInfo from 'components/layout/UserInfo';
import Login from 'components/Login';
import RecaptchaV2 from 'components/RecaptchaV2';
import LeftBanner from 'components/banners/LeftBanner';
import RightBanner from 'components/banners/RightBanner';
import TopBanner from 'components/banners/TopBanner';
import BottomBannerOne from 'components/banners/BottomBannerOne';
import BottomBannerTwo from 'components/banners/BottomBannerTwo';
import BottomBannerThree from 'components/banners/BottomBannerThree';
import BottomVideoBanner from 'components/banners/BottomVideoBanner';
import { Trans, i18n } from '~/i18n';

import App from './App.vue';
import router from './router';
import store from './store';

window.$ = $;

Vue.prototype.$Trans = Trans;

Vue.use(VModal);
Vue.use(VueIziToast);

Vue.component('AsideLeft', AsideLeft);
Vue.component('SocialRight', SocialRight);
Vue.component('UserInfo', UserInfo);
Vue.component('Login', Login);
Vue.component('LeftBanner', LeftBanner);
Vue.component('RightBanner', RightBanner);
Vue.component('TopBanner', TopBanner);
Vue.component('BottomBannerOne', BottomBannerOne);
Vue.component('BottomBannerTwo', BottomBannerTwo);
Vue.component('BottomBannerThree', BottomBannerThree);
Vue.component('BottomVideoBanner', BottomVideoBanner);

Vue.config.productionTip = false;

// Create a global head instance
const head = createHead();
Vue.use(UnheadPlugin);

export default new Vue({
    expose: [],
    router,
    store,
    i18n,
    unhead: head,
    render: (h) => h(App),
}).$mount('#app');
