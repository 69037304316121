<template>
    <li
        id="glogin"
        ref="googleLoginButton"
        class="google-login__button list-social__item"
    />
</template>

<script>
export default {
    name: 'GoogleLoginButton',
    expose: [],
    mounted() {
        const loginDiv = this.$refs.googleLoginButton;
        google.accounts.id.renderButton(loginDiv, {
            type: 'icon',
            size: 'Large',
            shape: 'circle',
        });
    },
};
</script>

<style>
.google-login__button {
    height: 2.5rem;
}
</style>
