import { configService } from '~/api/modules/configService';

/*

    КОПИРУЕТ СВОЙСТВА ИЗ objectFrom В objectTo - ТОЛЬКО ТЕ , КОТОРЫЕ ЕСТЬ В objectTo

*/
export const copyExactProperties = (objectFrom, objectTo) => {
    Object.keys(objectFrom).forEach((key) => {
        if (objectTo.hasOwnProperty(key)) {
            objectTo[key] = objectFrom[key];
        }
    });
};

// pull banners of ads

export const getExtAds = (mode, cnt) => {
    axios
        .post('/service/getAds', {
            mode,
            cnt,
        })
        .then(
            (response) => {
                const _response = JSON.parse(response.request.responseText);
                if (_response.error && _response.error === '0') {
                    displayAds(_response.result.adsList);
                }
            },
            (reject) => {
                if (reject.response) {
                    console.log(reject.response.data.errorMessage);
                }
            },
        );
};

export const displayAds = (banners) => {
    const regexScript = /<script[\s\S]+<\/script>/gi;
    // let regexScript = /<script.*?<\/script>/gi
    // let regexScript = /<SCRIPT[\s\S]+<\/SCRIPT>/g
    const regexDiv = /<div.*?div>/g;

    banners.forEach((banner) => {
        const scripts = findTags(regexScript, banner);
        const divs = findTags(regexDiv, banner);
        // console.log(scripts)

        addBanner(scripts, divs, banner);
    });
};

const findTags = (regex, banner) =>
    // console.log(banner.src.match(regex))
    [...banner.src.matchAll(regex)];

const setBannerPosition = (banner) => {
    switch (banner.mode) {
    case '1':
        return 'left-banner_ext';
        break;
    case '2':
        return 'right-banner_ext';
        break;
    case '3':
        return 'top-banner_ext';
        break;
    case '4':
        return 'bottom-banner1_ext';
        break;
    case '5':
        return 'bottom-banner2_ext';
        break;
    case '6':
        return 'bottom-banner3_ext';
        break;
    case '7':
        return 'bottom-video-banner_ext';
        break;
        // in case mode is undefined
    default:
        return 'bottom-banner2_ext';
    }
};

const addBanner = (js, divs, banner) => {
    // console.log(setBannerPosition(banner))
    const bannerPosition = document.getElementsByClassName(setBannerPosition(banner))[0];
    // console.log(bannerPosition)

    if (bannerPosition) {
        divs.forEach((element) => {
            const divString = element[0];
            const div = document.createElement('div');
            div.innerHTML = divString;

            bannerPosition.append(div);
        });

        js.forEach((element) => {
            if (element[0].match(/src=".*?"/)) {
                const src = element[0].match(/src="(.*?)"/);
                // console.log(src)

                const script = document.createElement('script');
                script.src = src[1];
                bannerPosition.append(script);
            } else {
                const scriptContent = element[0].match(/<script.*?>([\s\S]+)<\/script>/i);
                // console.log(scriptContent)

                const script = document.createElement('script');
                script.innerHTML = scriptContent[1];
                bannerPosition.append(script);
            }
        });
    }
};

export function shortenToFourDecimalPlaces(str) {
    const dotIndex = str.indexOf('.');
    if (dotIndex === -1) {
        return str;
    }

    return str.slice(0, dotIndex + 5);
}

export const openWindow = (url, title, options = {}) => {
    if (typeof url === 'object') {
        options = url;
        url = '';
    }

    options = {
        url, title, width: 600, height: 720, ...options,
    };

    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;
    const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
    const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height;

    options.left = width / 2 - options.width / 2 + dualScreenLeft;
    options.top = height / 2 - options.height / 2 + dualScreenTop;

    const optionsStr = Object.keys(options)
        .reduce((acc, key) => {
            acc.push(`${key}=${options[key]}`);
            return acc;
        }, [])
        .join(',');

    const newWindow = window.open(url, title, optionsStr);

    if (window.focus) {
        newWindow.focus();
    }

    return newWindow;
};

const calculateSize = (img, maxWidth, maxHeight) => {
    let { width } = img;
    let { height } = img;

    // Calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
    }

    return [width, height];
};

export const imageCompressor = async (file) => {
    const MAX_WIDTH = 180;
    const MAX_HEIGHT = 180;
    const MIME_TYPE = 'image/webp';
    const QUALITY = 0.7;

    return new Promise((resolve, reject) => {
        const blobURL = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobURL;
        img.onerror = function () {
            URL.revokeObjectURL(this.src);
            // Handle the failure properly
            console.log('Cannot load image');
            reject();
        };

        img.onload = function () {
            URL.revokeObjectURL(this.src);
            const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            resolve(canvas.toDataURL(MIME_TYPE, QUALITY));
        };
    });
};

export function getEmojiByCountryCode(countryCode) {
    switch (countryCode) {
    case 'us':
        return '🇺🇸';
    case 'gb':
        return '🇬🇧';
    case 'au':
        return '🇦🇺';
    case 'ca':
        return '🇨🇦';
    }
}

export const isBrokerReferralLink = () => localStorage.getItem('urBid');

export const isFullSignUpForm = async () => {
    const { getConfig } = configService();
    const { data } = await getConfig();

    if (data.error && data.error === '0') {
        return data.result[0].IS_SIGNUP2 === '1';
    }

    return false;
};
