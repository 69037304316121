import { Trans } from '~/i18n';
import Home from '~/views/Home.vue';

const pathLocales = (await Trans.getSupportedLocales())
    .filter((locale) => locale !== 'us')
    .join('|');

export const routes = [
    {
        path: `/:locale(${pathLocales})?`,
        component: {
            template: '<router-view />',
        },
        beforeEnter: Trans.routeMiddleware,
        children: [
            {
                path: '',
                name: 'home',
                component: Home,
            },
            {
                path: 'brk/:bid/:subid1?/:subid2?/:subid3?',
                name: 'BrokerTraffic',
                component: Home,
            },
            {
                path: 'newreferral/:refid',
                name: 'NewReferral',
                component: Home,
            },
            {
                path: 'rules',
                name: 'rules',
                component: () => import('@/views/footer/Rules'),
            },
            {
                path: 'contact',
                name: 'contact',
                component: () => import('@/views/footer/Contact'),
            },
            {
                path: 'faq',
                name: 'faq',
                component: () => import('@/views/footer/FAQ'),
            },
            {
                path: 'coinbase',
                name: 'coinbase',
                component: () => import('@/views/footer/Coinbase'),
            },
            {
                path: 'privacy-policy',
                name: 'privacy-policy',
                component: () => import('@/views/footer/PrivacyPolicy'),
            },
            {
                path: 'how-to-make-money-online',
                name: 'guide',
                component: () => import('@/views/footer/Guide'),
            },
            {
                path: 'account',
                name: 'account',
                component: () => import('@/views/auth/Account'),
                meta: { authorize: true },
            },
            {
                path: 'balance',
                name: 'balance',
                component: () => import('@/views/auth/Balance'),
                meta: { authorize: true },
            },
            {
                path: 'balance-details/:status/:earnType',
                name: 'balance-details',
                component: () => import('@/views/auth/BalanceDetails'),
                meta: { authorize: true },
            },
            {
                path: 'withdraw',
                name: 'withdraw',
                component: () => import('@/views/auth/Withdraw'),
                meta: { authorize: true },
            },
            {
                path: 'register',
                name: 'register',
                component: () => import('@/views/auth/RegisterPage'),
            },
            {
                path: 'refer-earn',
                name: 'referals',
                component: () => import('@/views/auth/ReferralPage'),
            },
            {
                path: 'earn-money-online',
                name: 'offers',
                component: () => import('~/views/Offers.vue'),
            },
            {
                path: 'earn-money-online/best',
                name: 'hot-offers',
                component: () => import('~/views/HotOffers.vue'),
            },
            {
                path: 'surveys-for-money',
                name: 'surveys',
                component: () => import('~/views/Surveys.vue'),
            },
            {
                path: 'surveys-for-money/profile',
                name: 'survey',
                component: () => import('@/views/auth/Survey'),
                meta: { authorize: true },
            },
            {
                path: 'offer-walls',
                name: 'offer-walls',
                component: () => import('@/views/auth/OfferWalls'),
            },
            {
                path: 'get-paid-to-play-games',
                name: 'paid-2-games',
                component: () => import('@/views/auth/PaidToGames'),
            },
            {
                path: 'get-paid-to-watch-videos',
                name: 'video',
                component: () => import('@/views/auth/Video'),
            },
            {
                path: 'click-earn',
                name: 'click',
                component: () => import('@/views/auth/Click'),
            },
            {
                path: 'inbox',
                name: 'inbox',
                component: () => import('@/views/auth/Inbox'),
                meta: { authorize: true },
            },
            {
                path: 'regpath/:uid',
                name: 'regpath',
                component: () => import('@/views/auth/RegpathPage'),
            },
            {
                path: 'confirm/:id',
                name: 'confirm',
                component: () => import('@/views/ConfirmEmail'),
                props: true,
            },
            {
                path: 'offers-internal/:uid',
                name: 'offers-internal',
                component: () => import('@/views/auth/OffersInternal'),
            },
            {
                path: '404',
                name: '404',
                component: () => import('@/views/NotFound'),
            },

            {
                path: 'forgot',
                name: 'ForgotPassword',
                component: () => import('@/views/ForgotPass'),
            },
            {
                path: 'recover-pwd/:token',
                name: 'RecoverPassword',
                component: () => import('@/views/RecoverPass'),
            },
            {
                path: 'bad-offer-report/:offerId/:offerLabel',
                name: 'BadOfferReport',
                component: () => import('@/views/auth/BadOfferReport.vue'),
                meta: { authorize: true },
            },
            {
                path: 'abuse/:uid',
                name: 'AbuseReport',
                component: () => import('@/views/auth/AbuseReport.vue'),
                meta: { authorize: true },
            },
            {
                path: 'auth/:token',
                name: 'Auth',
                component: () => import('@/views/Auth.vue'),
            },
            {
                path: ':offerType(earn-money-online|surveys-for-money)/:offerFilter(best|)?/:offerSlug',
                name: 'offer',
                component: () => import('~/views/Offer.vue'),
            },
            {
                path: '*',
                redirect: { name: '404' },
            },
        ],
    },
];
