<template>
    <div>
        <modal name="login" height="auto" adaptive>
            <RecaptchaV3 ref="recaptchaV3" />
            <RecaptchaV2 @verify="submit" />
            <div class="h-100 px-5 py-4 w-100">
                <h3 class="text-center text-danger" v-text="modalErrorMsg" />
                <div class="login-title">
                    <h2 class="mt-2 text-center text-uppercase">
                        <span class="text-primary">Sign In</span>
                    </h2>
                </div>

                <form
                    class="align-items-start d-flex flex-column justify-content-center modal__content mx-auto"
                    novalidate
                    @submit.prevent="submit()"
                >
                    <div class="form-row mb-3 w-100">
                        <input
                            v-model="form.login.value"
                            type="email"
                            class="form-control"
                            placeholder="Email"
                        />
                        <div
                            :class="{
                                'invalid-feedback d-block':
                                    form.login.verified === 'no',
                            }"
                            v-text="form.login.errorMsg"
                        />
                    </div>
                    <div class="form-row mb-3 w-100">
                        <div class="password-input">
                            <input
                                v-model="form.pwd.value"
                                class="form-control"
                                placeholder="Password"
                                :type="passwordVisible ? 'text' : 'password'"
                            />
                            <button type="button" class="show-password-button" @click="togglePasswordVisibility">
                                <span v-if="passwordVisible">
                                    <i class="fas fa-eye-slash" />
                                </span>
                                <span v-else>
                                    <i class="fas fa-eye" />
                                </span>
                            </button>
                        </div>
                        <div
                            :class="{
                                'invalid-feedback d-block':
                                    form.pwd.verified === 'no',
                            }"
                            v-text="form.pwd.errorMsg"
                        />
                    </div>
                    <span class="forgot" @click="$modal.hide('login')">
                        <router-link
                            :to="$Trans.i18nRoute({ name: 'ForgotPassword' })"
                        >
                            Forgot password
                        </router-link>
                    </span>
                    <div class="flex">
                        <input
                            type="submit"
                            class="button button_large button_orange login-form__button"
                            value="Log In"
                            :class="{ disabled: button === 'disabled' }"
                        />

                        <LoginSocials
                            @error="setErrorMessageSignUp"
                            @success="signInComplete"
                        />
                    </div>
                </form>

                <p class="login-signup">
                    <span>or</span>
                    <span @click="$modal.hide('login')">
                        <router-link
                            :to="$Trans.i18nRoute({ name: 'register' })"
                        >
                            Sign up
                        </router-link>
                    </span>
                </p>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios';
import { Validation } from 'utils/validation';
import { mapGetters } from 'vuex';
import RecaptchaV3 from './RecaptchaV3.vue';
import RecaptchaV2 from './RecaptchaV2.vue';
import LoginSocials from './LoginSocials.vue';

export default {
    name: 'LoginForm',
    expose: [],
    components: {
        RecaptchaV3,
        RecaptchaV2,
        LoginSocials,
    },
    computed: {
        ...mapGetters(['auth']),
    },
    watch: {
        auth(auth) {
            if (auth) this.$modal.hide('login');
        },
    },
    data() {
        return {
            passwordVisible: false,
            validation: new Validation(),
            form: {
                login: {
                    value: '',
                    required: true,
                    errorMsg: '',
                    verified: 'pending',
                },
                pwd: {
                    value: '',
                    required: false,
                    errorMsg: '',
                    verified: 'pending',
                },
                fp_val: {
                    value: '',
                    required: true,
                    errorMsg: '',
                    verified: 'pending',
                },
                'g-recaptcha-response': {
                    value: '',
                    required: true,
                    errorMsg: '',
                    verified: 'pending',
                },
            },
            button: 'enabled',
            modalErrorMsg: '',
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },
        async submit(recaptchaResponse) {
            this.form.fp_val.value = this.$store.getters.visitorId;
            this.form['g-recaptcha-response'].value = recaptchaResponse || (await this.$refs.recaptchaV3.verify());

            this.validation.check(this.form);

            if (this.validation.status === 'done') {
                this.button = 'disabled';
                axios
                    .post('/service/login', {
                        login: this.form.login.value,
                        pwd: this.form.pwd.value,
                        fp_val: this.form.fp_val.value,
                        'g-recaptcha-response':
                            this.form['g-recaptcha-response'].value,
                        cv: recaptchaResponse ? '2' : '3',
                        locale: this.$Trans.currentLocale,
                    })
                    .then(
                        (response) => {
                            try {
                                const _response = JSON.parse(
                                    response.request.responseText,
                                );
                                if (_response.error) {
                                    if (_response.error === '0') {
                                        this.$modal.hide('login');
                                        this.$store.dispatch('authentication', {
                                            status: true,
                                            token: _response.result.token,
                                        });
                                        this.$store.dispatch('updateUserData', {
                                            token: _response.result.token,
                                        });
                                        this.signInComplete();
                                    } else {
                                        this.modalErrorMsg = _response.errorMessage;
                                    }
                                }
                            } finally {
                                this.button = 'enabled';
                            }
                        },

                        ({ response }) => {
                            if (response) {
                                if (response.data.errorMessage) {
                                    this.modalErrorMsg = response.data.errorMessage;
                                }
                            }

                            if (response.data.error === '133') {
                                this.$modal.show('captcha');
                            }

                            this.button = 'enabled';
                        },
                    );
            }
        },
        signInComplete() {
            this.$store.dispatch('getUserData');
            this.$modal.hide('login');
            this.$router.push(this.$Trans.i18nRoute({ name: 'hot-offers' }));
        },
        setErrorMessageSignUp(message) {
            this.modalErrorMsg = message.errorMessage;
        },
    },
};
</script>

<style>
.login-form__button {
    margin-right: 8px;
}

.login-title {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.login-signup {
    font-size: large;
    text-align: center;
}
</style>
