import { request } from '~/api';

export const getUserPaymentHistory = async (page) => request({
    method: 'GET',
    url: '/service/getUserPaymentHistory',
    params: { page },
});
export const postPin = async (type) => request({
    method: 'POST',
    url: '/service/sendPin',
    data: { action: type },
});
export const postBadOfferReport = async ({
    mid,
    comment,
    gRecaptchaResponse,
}) => request({
    method: 'POST',
    url: '/service/createBadOfferReport',
    data: { mid, comment, 'g-recaptcha-response': gRecaptchaResponse },
});
export const getTwToken = async (fpVal, locale) => axios.get('/service/getTwToken', {
    params: {
        fp_val: fpVal,
        locale,
    },
});
export const postAbuseReport = async ({
    userId,
    name,
    email,
    comment,
    gRecaptchaResponse,
}) => request({
    method: 'POST',
    url: '/service/sendAbuseReport',
    data: {
        userId,
        name,
        email,
        comment,
        'g-recaptcha-response': gRecaptchaResponse,
    },
});
export const getOffer = async (offerId, locale) => request({
    method: 'GET',
    url: `/service/getOffer/${offerId}`,
    params: { locale },
});
export const getUserLocaleByIp = () => request({
    method: 'GET',
    url: '/service/getCountryCodeForUser',
});
export const getGeoListCountries = () => request({ method: 'GET', url: '/service/geoListCountries' });
export const postContactUs = ({
    name, email, comment, gRecaptchaResponse,
}) => request({
    method: 'POST',
    url: '/service/sendMailFromContactUs',
    data: {
        name,
        email,
        comment,
        'g-recaptcha-response': gRecaptchaResponse,
    },
});

export const getBankCardFrame = async () => request({
    method: 'GET',
    url: '/service/getTipaltiIFrame',
});

export const getBrkCode = async (mode) => request({
    method: 'GET',
    url: '/service/getBrkCode',
    params: { mode },
});

export const getBrkPixel = async (mode, uid) => request({
    method: 'GET',
    url: '/service/getBrkPixel',
    params: { mode, uid },
});
