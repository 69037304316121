<template>
    <li class="list-social__item">
        <button type="button" class="twitter-button" @click.prevent="login">
            <img
                class="twitter-picture"
                width="42px"
                height="42px"
                src="@/assets/img/twitter.svg"
                preserveAspectRatio="none"
                alt="twitter button login"
            />
        </button>
    </li>
</template>

<script>
import { defineComponent } from 'vue';
import { openWindow } from '~/utils/helpers';
import { getTwitterOauthUrl } from '~/configs/twitter_oAuth';
import { getTwToken } from '~/api/services';

const baseUrl = process.env.VUE_APP_BASE_URL;

export default defineComponent({
    expose: [],
    emits: ['error', 'success'],
    methods: {
        async login() {
            const { data } = await getTwToken(this.$store.getters.visitorId, this.$Trans.currentLocale);
            const url = getTwitterOauthUrl(data.result.oauth_token);
            const loginPopup = openWindow(url);

            const timer = setInterval(() => {
                if (loginPopup.closed) {
                    clearInterval(timer);
                    this.$emit('error', {
                        error: true,
                        errorMessage: 'Undefined error',
                    });
                }
            }, 1000);

            const self = this;
            window.addEventListener(
                'message',
                function messageListener(event) {
                    if (event.origin !== baseUrl) return;

                    clearInterval(timer);
                    loginPopup.close();
                    if (event.data.success) {
                        self.$store.dispatch('authentication', {
                            status: true,
                            token: event.data.token,
                        });
                        self.$store.dispatch('updateUserData', {
                            token: event.data.token,
                        });
                        self.$emit('success');
                    } else if (event.data.error) {
                        self.$emit('error', event.data);
                    }

                    window.removeEventListener(
                        'message',
                        messageListener,
                        false,
                    );
                },
                false,
            );
        },
    },
});
</script>

<style scoped>
.twitter-button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
}
</style>
