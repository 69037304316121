<template>
    <div id="app-content">
        <ThirdPartyScriptsLoader />
        <div v-if="$store.state.base.preloader" class="loader">
            <div class="loader__icon-load" />
        </div>
        <div class="main-wrapper">
            <Header />
            <main class="content bg-cont">
                <router-view />
            </main>
            <Footer />
        </div>
        <Login />
    </div>
</template>

<script>
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import { initGoogleOauth } from '~/configs/google_oAuth';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import ThirdPartyScriptsLoader from '~/ThirdPartyScriptsLoader.vue';

export default defineComponent({
    components: {
        ThirdPartyScriptsLoader,
        Header,
        Footer,
    },
    expose: [],
    computed: {
        ...mapGetters(['auth', 'country']),
    },
    watch: {
        country(newValue) {
            this.$Trans.switchLanguage(newValue.toLowerCase());
        },
    },
    async created() {
        await this.$store.dispatch('getFingerprint');
        initGoogleOauth(this.loginWithGoogle, this.$store.getters.visitorId);

        if (!this.auth) google.accounts.id.prompt();
    },
    methods: {
        async loginWithGoogle(response) {
            const { data: authData } = await axios.post('service/glLogin', {
                fp_val: this.$store.getters.visitorId,
                glToken: response.credential,
                locale: this.$Trans.currentLocale,
            });
            this.$store.dispatch('authentication', {
                status: true,
                token: authData.result.token,
            });
            this.$store.dispatch('updateUserData', {
                token: authData.result.token,
            });

            this.signUpComplete();
        },
        signUpComplete() {
            this.$store.dispatch('getUserData');
            this.$router.push(this.$Trans.i18nRoute({ name: 'hot-offers' }));
        },
    },
});
</script>

<style lang="scss">
button {
    outline: none !important;
    &:active {
        outline: none !important;
    }
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none !important;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.form-control,
.custom-select {
    box-shadow: none !important;
    height: 48px !important;
    border: 1px solid #e5e5e5 !important;
    font-size: 16px;
    line-height: 18px;
    padding: 14px 40px 14px 20px;
    color: #7c8a96;
    border-radius: 24px !important;
    z-index: 2;
    padding-right: 10px;
    width: 100%;
    outline: none;
    font-weight: 300;
    &:focus {
        outline: none;
    }

    option {
        font-weight: 300;
    }
}

.invalid-feedback {
    font-size: 100% !important;
}

.box-field__label {
    .color {
        color: #ed1c24 !important;
    }
}

.list-checkbox__label {
    &:before {
        display: none;
    }
}

.custom-control-label {
    font-size: 16px;
    color: #36454e;
    line-height: 19px;
    font-weight: 300;
}

.box-field__label {
    font-weight: 300;
}

.search-block {
    position: relative;

    .remove-search-data {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
    }
}

.form-control-textarea {
    box-shadow: none !important;
    border: 1px solid #e5e5e5 !important;
    font-size: 16px;
    line-height: 18px;
    padding: 14px 40px 14px 20px;
    color: #7c8a96;
    border-radius: 24px !important;
    z-index: 2;
    padding-right: 10px;
    width: 100%;
    outline: none;
    font-weight: 300;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    color: white;
    filter: drop-shadow(0px 2px black);
}
</style>
