<script>
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

const siteKey = process.env.VUE_APP_RECAPTCHA_V3_KEY;

Vue.use(VueReCaptcha, { siteKey });

export default {
    name: 'RecaptchaV3',
    emits: ['recaptcha'],
    methods: {
        async verify() {
            await this.$recaptchaLoaded();

            return await this.$recaptcha('login');
        },
    },
    render: () => null,
};
</script>
