<template>
    <ul class="list-social">
        <FacebookLogin @success="$emit('success')" />
        <GoogleLoginButton @success="$emit('success')" />
        <TwitterLogin
            @error="$emit('error', $event)"
            @success="$emit('success')"
        />
    </ul>
</template>

<script>
import FacebookLogin from './FacebookLogin.vue';
import GoogleLoginButton from './GoogleLoginButton.vue';
import TwitterLogin from './TwitterLogin.vue';

export default {
    components: {
        FacebookLogin,
        GoogleLoginButton,
        TwitterLogin,
    },
    expose: [],
    emits: ['error', 'success'],
    methods: {},
};
</script>
