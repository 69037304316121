import axios from 'axios';

window.axios = axios;

const AUTH_TOKEN = localStorage.getItem('session-token') || '';

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/api';
axios.defaults.headers.common.XUniqueAuth = AUTH_TOKEN;

/*

    INTERCEPTORS.REQUEST НА НАЛИЧИЕ ТОКЕНА НЕ СТАВИТСЯ ТАК, КАК INTERCEPTORS ВЫСТАВЛЯЕТСЯ ГЛОБАЛЬНО ДЛЯ ВСЕХ ЗАПРОСОВ В ТОМ ЧИСЛЕ ДЛЯ ЛОГИНА И РЕГИСТРАЦИИ НЕАВТОРИЗОВАННЫХ ПОЛЬЗОВАТЕЛЕЙ

*/
axios.interceptors.request.use((config) => {
    /*

    ЕСЛИ AXIOS ПРОСТАВИТ СЛЕШ НА ЗАКРЫТИЕ АДРЕСНОЙ СТРОКИ - ОБРЕЗАЕМ ЕГО

  */
    config.url = config.url.replace(/\/$/, '');
    return config;
});

axios.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error),
);

export const request = async (config) => {
    try {
        return await axios.request(config);
    } catch ({ response }) {
        return { error: response.data };
    }
};

export default axios;
