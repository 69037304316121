<template>
    <modal name="captcha" height="auto" adaptive>
        <div class="px-5 py-4 w-full md:w-100 h-100">
            <h3 class="text-center">
                Additional verification
            </h3>
            <div
                class="d-flex align-items-center justify-content-center flex-column my-3"
            >
                <VueRecaptcha
                    :sitekey="siteKey"
                    :load-recaptcha-script="true"
                    @verify="verify"
                    @expired="expiredMethod"
                    @error="errorMethod"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

const siteKey = process.env.VUE_APP_RECAPTCHA_V2_KEY;

export default {
    name: 'RecaptchaV2',
    components: {
        VueRecaptcha,
    },
    data() {
        return {
            response: null,
            siteKey,
        };
    },
    methods: {
        verify(event) {
            this.response = event;

            this.$emit('verify', event);
            this.$modal.hide('captcha');
        },
        expiredMethod(event) {
            this.response = event;
        },
        errorMethod(event) {
            this.response = event;
        },
    },
};
</script>
