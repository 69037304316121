<template><div /></template>

<script setup>
import { toRefs, watchEffect } from 'vue';
import { useRoute } from 'vue-router/composables';
import { updateRefStat, updateBrokerStat } from '~/api/modules/referralService';

const route = useRoute();
const { params } = toRefs(route);

watchEffect(() => {
    if (route.name === 'NewReferral' && params.value.refid) {
        localStorage.setItem('urRefid', params.value.refid);
        updateRefStat(params.value.refid);
    }
});

watchEffect(() => {
    if (route.name === 'BrokerTraffic' && params.value.bid) {
        localStorage.setItem('urBid', params.value.bid);
        localStorage.setItem('urSubid1', params.value.subid1 ?? '');
        localStorage.setItem('urSubid2', params.value.subid2 ?? '');
        localStorage.setItem('urSubid3', params.value.subid3 ?? '');

        updateBrokerStat({
            bid: params.value.bid,
            subid1: params.value.subid1 ?? '',
            subid2: params.value.subid2 ?? '',
            subid3: params.value.subid3 ?? '',
        });
    }
});
</script>
