import { request } from '../index.js';

const uri = '/service/';

export const updateRefStat = async (uid) => request({
    method: 'POST',
    url: '/service/updateRefStat',
    data: { uid },
});

export const updateBrokerStat = async (data) => request({
    method: 'POST',
    url: '/service/addBrkClick',
    data,
});

export const getReferralStat = async (page) => request({
    method: 'GET',
    url: `${uri}getReferrals`,
    params: { page },
});

export const getReferralTransactions = async () => request({
    method: 'GET',
    url: `${uri}getRefTrans`,
});

export const getTopTransactions = async () => request({
    method: 'GET',
    url: `${uri}getTopTrans`,
});
