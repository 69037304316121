<template>
    <Modal :name="name" :adaptive="adaptive" :height="height">
        <div
            class="p-5 d-flex justify-content-center align-item-center flex-column text-center position-relative"
        >
            <button
                v-if="closable"
                class="modal-close text-muted border-0 bg-transparent"
                @click="close"
            >
                <i class="fa fa-2x fa-times" />
            </button>
            <h3>
                {{ title }}
            </h3>
            <div
                class="pt-5 d-flex justify-content-center align-item-center flex-row"
            >
                <slot name="content" />
            </div>
        </div>
    </Modal>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String,
            default: 'base-modal',
        },
        closable: {
            type: Boolean,
            default: false,
        },

        name: {
            type: String,
            default: 'base-modal',
        },
        adaptive: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: '200px',
        },
    },
    expose: [],
    methods: {
        close() {
            this.$modal.hide(this.name);
        },
        show() {
            this.$emit('errorBlankProfileCloseGoAccount');
        },
    },
});
</script>
